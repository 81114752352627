import React, { useState } from "react";
import { Link } from "gatsby";
import styles from "./footer.module.scss";

import NewsletterSignUp from "./newsletterSignUp";

const Footer = () => {
  const [links] = useState([
    { route: "", title: "HOME" },
    { route: "meet-linda", title: "MEET LINDA" },
    { route: "on-the-issues", title: "ON THE ISSUES" },
    { route: "get-involved", title: "GET INVOLVED" },
    { route: "contact-linda", title: "CONTACT LINDA" },
    {
      route: "https://secure.actblue.com/donate/friends-of-linda-ichiyama",
      title: "CONTRIBUTE",
    },
  ]);

  return (
    <>
      <div className={styles.Footer}>
        <div className={styles.newsletter_container}>
          <p>Sign up to receive emails for news and upcoming events.</p>
          <NewsletterSignUp
            data={{
              inputPlaceholder: "email@email.com",
              submitButton: "GET THE NEWSLETTER",
            }}
            inFooter={true}
          />
        </div>
        <address className={styles.address_lg}>
          <p>Paid for by Friends of Linda Ichiyama</p>
          <p>P.O. Box 1707</p>
          <p>Aiea, HI 96701</p>
          <p>(808) 392-4547</p>
        </address>
        <address className={styles.address_md}>
          <p>Paid for by Friends of Linda Ichiyama</p>
          <p>P.O. Box 1707, Aiea, HI 96701</p>
          <p>(808) 392-4547</p>
        </address>
      </div>
      <nav className={styles.footer_nav_lg}>
        {" "}
        <p className={styles.navigation_title}>NAVIGATION</p>
        <ul>
          {links.map((link) => {
            if (link.title !== "CONTRIBUTE") {
              return (
                <li className={styles.link_li} key={link.title}>
                  <Link to={"/" + link.route}>{link.title}</Link>
                </li>
              );
            } else {
              return (
                <li className={styles.link_li} key={link.title}>
                  <a
                    href={link.route}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.title}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </nav>
    </>
  );
};

export default Footer;
