// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-linda-js": () => import("./../../../src/pages/contact-linda.js" /* webpackChunkName: "component---src-pages-contact-linda-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meet-linda-js": () => import("./../../../src/pages/meet-linda.js" /* webpackChunkName: "component---src-pages-meet-linda-js" */),
  "component---src-pages-on-the-issues-js": () => import("./../../../src/pages/on-the-issues.js" /* webpackChunkName: "component---src-pages-on-the-issues-js" */),
  "component---src-pages-playground-js": () => import("./../../../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */)
}

