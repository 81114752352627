import React, { useState } from "react";
import { Link } from "gatsby";

import styles from "./header.module.scss";

import logo from "../images/linda-ichiyama-logo.png";

const Header = () => {
  const [links] = useState([
    { route: "", title: "HOME" },
    { route: "meet-linda", title: "MEET LINDA" },
    { route: "on-the-issues", title: "ON THE ISSUES" },
    { route: "get-involved", title: "GET INVOLVED" },
    { route: "contact-linda", title: "CONTACT LINDA" },
    {
      route: "https://secure.actblue.com/donate/friends-of-linda-ichiyama",
      title: "CONTRIBUTE",
    },
  ]);

  const [navSmallIsOpen, setNavSmallIsOpen] = useState(false);

  let handleToggleNavSmall = () => {
    return setNavSmallIsOpen(!navSmallIsOpen);
  };

  return (
    <header className={styles.Header}>
      <Link to="/">
        <img src={logo} alt="Linda Ichiyama - Home" className={styles.logo} />
      </Link>
      <nav className={styles.nav_large}>
        <ul className={styles.navigation_ul}>
          {links.map((link, index) => {
            if (link.title !== "CONTRIBUTE") {
              return (
                <li key={index}>
                  <Link
                    to={`/${link.route}`}
                    className={styles.navigation_link}
                    name={link.title}
                  >
                    {link.title}
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={index}>
                  <a
                    href={link.route}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.contribute_button}
                  >
                    {link.title}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </nav>
      <nav className={styles.nav_small}>
        <button onClick={handleToggleNavSmall} aria-label="Menu Button">
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
        </button>
        {!navSmallIsOpen ? null : (
          <ul className={styles.navigation_ul}>
            {links.map((link, index) => {
              if (link.title !== "CONTRIBUTE") {
                return (
                  <li key={index} className={styles.nav_link_regular}>
                    <Link
                      to={`/${link.route}`}
                      className={styles.navigation_link}
                      name={link.title}
                      onClick={handleToggleNavSmall}
                    >
                      {link.title}
                    </Link>
                  </li>
                );
              } else {
                return (
                  <li key={index}>
                    <a
                      href={link.route}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.contribute_button}
                    >
                      {link.title}
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        )}
      </nav>
    </header>
  );

  // needs logo
  // needs nav buttons
  // needs contribute button
};

export default Header;
