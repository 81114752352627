import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./newsletterSignUp.module.scss";

//only need this in dev
// const GATEWAY_URL = process.env.GATEWAY_URL;
const required = "The email address you entered is invalid.";

const NewsletterSignUp = (props) => {
  //react-hook-form start
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    reset,
    setError,
  } = useForm();

  const {
    register: registerF,
    handleSubmit: handleSubmitF,
    errors: errorsF,
    formState: { isSubmitting: isSubmittingF },
    reset: resetF,
    setError: setErrorF,
  } = useForm();

  const onSubmit = async (data) => {
    if (data.phone !== "") {
      return;
    }

    try {
      // change to GATEWAY_URL in dev
      await fetch("/api/email", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      props.inFooter ? resetF() : reset();
      props.inFooter ? setSubmittedF(true) : setSubmitted(true);
    } catch (err) {
      //handle server errors
      props.inFooter
        ? setErrorF(
            "submit",
            "submitError",
            `Oops, something went wrong!\n\n${err.message}.\nPlease make sure you are connected to the internet.`
          )
        : setError(
            "submit",
            "submitError",
            `Oops, something went wrong!\n\n${err.message}.\nPlease make sure you are connected to the internet.`
          );
    }
  };

  const showSubmitError = (msg) => (
    <p className={styles.message_error}>{msg}</p>
  );

  const [submitted, setSubmitted] = useState(false);
  const [submittedF, setSubmittedF] = useState(false);

  const showThankYou = (
    <div className={styles.thankYou_message}>
      <div>
        <h3>SIGNUP COMPLETE</h3>
        <p>Thank you for signing up!</p>
        <button
          onClick={() => {
            props.inFooter ? setSubmittedF(false) : setSubmitted(false);
          }}
        >
          CLOSE
        </button>
      </div>
    </div>
  );

  //react-hook-form end

  return (
    <div className={styles.NewsletterSignUp_container}>
      {submitted ? showThankYou : null}
      {submittedF ? showThankYou : null}

      <form
        method="post"
        onSubmit={
          props.inFooter ? handleSubmitF(onSubmit) : handleSubmit(onSubmit)
        }
        id={props.inFooter ? "newsletter_form_inFooter" : "newsletter_form"}
      >
        <label htmlFor="email">
          {!props.data.label ? null : props.data.label}
        </label>
        <div>
          <input
            type="text"
            id="email"
            name="email"
            placeholder={props.data.inputPlaceholder}
            ref={
              props.inFooter ? registerF({ required }) : register({ required })
            }
            form={
              props.inFooter ? "newsletter_form_inFooter" : "newsletter_form"
            }
            disabled={props.inFooter ? isSubmittingF : isSubmitting}
            className={props.inFooter ? styles.emailInputF : null}
          />
          <label htmlFor="phone" className={styles.phoneLabel}>
            <input
              type="phone"
              id="phone"
              name="phone"
              placeholder="Your phone number"
              autoComplete="nah-ah"
              form={
                props.inFooter ? "newsletter_form_inFooter" : "newsletter_form"
              }
              className={styles.phoneInput}
              ref={props.inFooter ? registerF() : register()}
            />
          </label>

          <input
            type="submit"
            className={styles.submit_button}
            value={props.data.submitButton}
            disabled={props.inFooter ? isSubmittingF : isSubmitting}
            form={
              props.inFooter ? "newsletter_form_inFooter" : "newsletter_form"
            }
          />
        </div>
      </form>
      {errors.email && (
        <div className={styles.message_error}>{errors.email.message}</div>
      )}
      {errors && errors.submit && showSubmitError(errors.submit.message)}

      {errorsF.email && (
        <div className={styles.message_error}>{errorsF.email.message}</div>
      )}
      {errorsF && errorsF.submit && showSubmitError(errorsF.submit.message)}
    </div>
  );
};

export default NewsletterSignUp;
