import React from "react";
import styles from "./layout.module.scss";

import Header from "./header";
import Footer from "./footer";
import SEO from "./seo";

export default ({ children }) => (
  <div className={styles.Layout}>
    <SEO title="Linda Ichiyama | Hawaii State Representative, House District 32" />
    <Header></Header>
    {children}
    {/* <ContactForm /> */}
    <Footer></Footer>
  </div>
);
